import { MemberGroupMembershipRole } from "@/product/common/member-group/common/member-group-drawer/__generated__/MemberGroupMembersTableRowFragment.graphql"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"

type Props = TestIDProps & {
  groupRole: MemberGroupMembershipRole
}

export default function MemberGroupMembershipRoleTag(props: Props) {
  const { testid = "MemberGroupMembershipRoleTag", groupRole } = props
  const theme = useTheme()
  const color = groupRole === "lead" ? "purple" : "grey"
  const colorIntensity = theme.palette.type === "dark" ? 400 : 200
  const textIntensity = theme.palette.type === "dark" ? 100 : 700

  return (
    <DiscoTag
      testid={`${testid}.${groupRole}`}
      name={groupRole === "lead" ? "Group Lead" : "Group Member"}
      backgroundColor={theme.palette.groovy[color][colorIntensity]}
      color={theme.palette.groovy.neutral[textIntensity]}
    />
  )
}
