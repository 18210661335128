import MemberGroupVisibilityChip from "@/admin/members/groups/MemberGroupVisibilityChip"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import AdminMemberGroupTable from "@/product/common/member-group/common/AdminMemberGroupTable"
import { GroupFormTableSkeleton } from "@/product/common/member-group/common/GroupFormTable"
import { CreateMemberGroupFormState } from "@/product/common/member-group/common/create/form/CreateMemberGroupForm"
import MemberGroupAvatarInput from "@/product/common/member-group/common/member-group-drawer/MemberGroupAvatarInput"
import { EditMemberGroupFormState } from "@/product/common/member-group/util/MemberGroupUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoFormControl,
  DiscoInput,
  DiscoInputSkeleton,
  DiscoSelect,
  DiscoTooltip,
} from "@disco-ui"
import DiscoColorPicker from "@disco-ui/color/DiscoColorPicker"
import { observer } from "mobx-react-lite"
import React from "react"

type Props = {
  tableLabel?: React.ReactElement
  showMembershipSelect?: boolean
  isSubGroup: boolean
  form: FormStore<CreateMemberGroupFormState> | FormStore<EditMemberGroupFormState>
}

const MemberGroupFormFields = observer((props: Props) => {
  const { form, tableLabel, isSubGroup, showMembershipSelect = true } = props
  const membersLabel = useLabel("admin_member")
  const classes = useStyles()
  const isEditing = "id" in form.state

  return (
    <>
      {/* Avatar Input */}
      <MemberGroupAvatarInput form={form} />

      {/* Group Name */}
      <DiscoFormControl
        label={isSubGroup ? "Sub-Group Name" : "Group Name"}
        errorMessages={form.errorsByField.name}
      >
        <DiscoInput
          inputProps={{ "data-testid": "MemberGroupFormFields.name-input" }}
          title={"Group Name"}
          value={form.state.name}
          onChange={(e) => (form.state.name = e.target.value)}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>

      <div className={classes.split}>
        {/* Color */}
        <DiscoColorPicker
          formControlProps={{
            label: "Color",
            errorMessages: form.errorsByField.color,
          }}
          value={form.state.color!}
          onChange={(v) => (form.state.color = v)}
          fullWidth
        />

        {/* Visibility */}
        <DiscoFormControl
          label={"Visibility"}
          errorMessages={form.errorsByField.visibility}
          tooltip={`${
            isEditing
              ? form.state.visibility === "admin_only"
                ? `Only visible to admins. ${membersLabel.plural} won't know if they are added to a private group. `
                : `Visible to everyone in the community (eg. on ${membersLabel.singular} profiles). `
              : ""
          }Group visibility can't be changed after creation.`}
        >
          {isEditing ? (
            <div className={classes.visibilityChip}>
              <MemberGroupVisibilityChip
                testid={"MemberGroupDetails"}
                memberGroup={{ visibility: form.state.visibility! }}
              />
            </div>
          ) : (
            <DiscoTooltip
              content={
                isSubGroup
                  ? "Sub-groups have the same visibility as the parent group"
                  : null
              }
            >
              <div>
                <DiscoSelect
                  testid={"MemberGroupFormFields.visibility"}
                  className={classes.select}
                  autoComplete={false}
                  options={[
                    {
                      value: "everyone",
                      title: "Everyone",
                      subtitle: `Visible to everyone in the community (eg. on ${membersLabel.singular} profiles).`,
                    },
                    {
                      value: "admin_only",
                      title: "Admins Only",
                      subtitle: `Only visible to admins. ${membersLabel.plural} won't know if they are added to a private group.`,
                    },
                  ]}
                  value={form.state.visibility}
                  onChange={(v) => (form.state.visibility = v)}
                  disabled={isSubGroup}
                />
              </div>
            </DiscoTooltip>
          )}
        </DiscoFormControl>
      </div>

      {/* Description */}
      <DiscoFormControl label={"Description"}>
        <DiscoInput
          inputProps={{ "data-testid": "MemberGroupFormFields.description-input" }}
          value={form.state.description}
          onChange={(e) => (form.state.description = e.target.value)}
          multiline
          minRows={3}
        />
      </DiscoFormControl>

      {/* Membership Select */}
      {showMembershipSelect && (
        <DiscoFormControl label={tableLabel || `Add ${membersLabel.plural}`}>
          <AdminMemberGroupTable form={form} isSubGroup={isSubGroup} />
        </DiscoFormControl>
      )}
    </>
  )
})

const useStyles = makeUseStyles((theme) => ({
  split: {
    display: "grid",
    gap: theme.spacing(1),
    gridTemplateColumns: "1fr 1fr",
  },
  select: {
    width: "100%",
  },
  visibilityChip: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "fit-content",
  },
}))

export default MemberGroupFormFields

export const MemberGroupFormFieldsSkeleton: React.FC = () => {
  const activeProduct = useActiveProduct()
  const membersLabel = useLabel(activeProduct ? "product_member" : "organization_member")

  return (
    <>
      <DiscoFormControl label={"Group Name"}>
        <DiscoInputSkeleton />
      </DiscoFormControl>
      <DiscoFormControl label={"Colour"}>
        <DiscoInputSkeleton />
      </DiscoFormControl>
      {activeProduct ? (
        <DiscoFormControl label={`Add ${membersLabel.plural} from ${activeProduct.name}`}>
          <DiscoInputSkeleton />
        </DiscoFormControl>
      ) : (
        <DiscoFormControl label={membersLabel.plural}>
          <GroupFormTableSkeleton />
        </DiscoFormControl>
      )}
    </>
  )
}
