import FormStore from "@/core/form/store/FormStore"
import MemberGroupMembershipRoleTag from "@/product/common/member-group/MemberGroupMembershipRoleTag"
import { MemberGroupMembershipTypeOption } from "@/product/common/member-group/common/MembershipTypeFilter"
import { AdminMemberGroupTableRowFragment$key } from "@/product/common/member-group/common/__generated__/AdminMemberGroupTableRowFragment.graphql"
import { CreateMemberGroupFormState } from "@/product/common/member-group/common/create/form/CreateMemberGroupForm"
import { EditMemberGroupFormState } from "@/product/common/member-group/util/MemberGroupUtils"
import OrganizationRoleTag from "@/role/OrganizationRoleTag"
import ProductRoleTag from "@/role/ProductRoleTag"
import { ProfileAvatarStackSkeleton } from "@/user/common/avatar-stack/ProfileAvatarStack"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoChip,
  DiscoIcon,
  DiscoIconButton,
  DiscoIconButtonSkeleton,
  DiscoTable,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { DiscoTagSkeleton } from "@disco-ui/tag/DiscoTag"
import { capitalize, TableCell } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"

interface MemberGroupTableRowProps {
  membershipKey: AdminMemberGroupTableRowFragment$key
  filter?: MemberGroupMembershipTypeOption["value"] | null
  form: FormStore<CreateMemberGroupFormState> | FormStore<EditMemberGroupFormState>
  index: number
  isSubGroup: boolean
}

function AdminMemberGroupTableRow({
  membershipKey,
  filter,
  form,
  index,
  isSubGroup,
}: MemberGroupTableRowProps) {
  const isMobile = useIsMobile()
  const { groupReporting } = useFeatureFlags()
  const classes = useStyles({ isGroupReportingActive: groupReporting })
  const [isWarningOpen, setIsWarningOpen] = useState(false)

  const membership = useFragment<AdminMemberGroupTableRowFragment$key>(
    graphql`
      fragment AdminMemberGroupTableRowFragment on MemberGroupMembership {
        id
        organizationMembership {
          role
          email
        }
        productMembership {
          role
          email
        }
        user {
          id
          fullName
          ...ProfileAvatarWithDetailsFragment
        }
      }
    `,
    toJS(membershipKey)
  )

  // Translate organization/product role to group membership type
  let membershipType =
    membership.organizationMembership.role === "member" ? "member" : "admin"
  if (membership.productMembership) {
    membershipType = membership.productMembership.role === "member" ? "member" : "admin"
  }
  if (filter && membershipType !== filter) return null

  const { user } = membership
  const TESTID = `AdminMemberGroupTableRow.${user.fullName}`
  const isLead = form.state.selectedLeadUserIds.includes(user.id)
  const isOrgSubGroup = isSubGroup && !form.state.product

  return (
    <div
      data-testid={TESTID}
      className={classes.row}
      data-userflowid={`AdminMemberGroupTableRow.${index}`}
    >
      <ProfileAvatarWithDetails
        userKey={user}
        testid={TESTID}
        details={
          membership.productMembership?.email || membership.organizationMembership.email
        }
        linkToProfile={false}
      />
      {groupReporting && (
        <div>
          {isLead && (
            <MemberGroupMembershipRoleTag
              testid={`${TESTID}.group-role`}
              groupRole={"lead"}
            />
          )}
        </div>
      )}
      {!isMobile && <div>{renderParentRoleChip()}</div>}
      {groupReporting ? (
        <div>
          <DiscoMoreActionsDropdown testid={`${TESTID}.actions`} rotateIcon>
            <DiscoDropdownItem
              testid={`${TESTID}.toggle-lead`}
              title={`Assign as Group ${isLead ? "Member" : "Lead"}`}
              onClick={() => {
                if (isLead) {
                  removeLead()
                  return
                }
                setIsWarningOpen(true)
              }}
              disabled={isOrgSubGroup || form.state.visibility === "admin_only"}
              tooltip={
                isOrgSubGroup
                  ? "Group Leads can only be assigned to the parent group"
                  : form.state.visibility === "admin_only"
                  ? "Groups Leads can't be assigned to groups with Admin Only visibility"
                  : null
              }
            />
            <DiscoDropdownItem
              testid={`${TESTID}.remove-member`}
              title={"Remove from group"}
              onClick={removeMember}
            />
          </DiscoMoreActionsDropdown>
          <DiscoWarningModal
            testid={`${TESTID}.toggle-lead`}
            variant={"primary"}
            modalContentLabel={"Assign group lead modal"}
            title={"Assign as Group Lead?"}
            description={`Are you sure you want to assign ${user.fullName} as a Group Lead? They will gain access to group and member reports, as well as the ability to take actions like emailing group members.`}
            isOpen={isWarningOpen}
            onClose={() => setIsWarningOpen(false)}
            confirmationButtonProps={{
              children: "Yes",
              onClick: () => {
                form.state.selectedLeadUserIds.push(user.id)
                setIsWarningOpen(false)
              },
            }}
          />
        </div>
      ) : (
        <div>
          <DiscoIconButton onClick={removeMember} testid={`${TESTID}.remove-member`}>
            <DiscoIcon icon={"close"} />
          </DiscoIconButton>
        </div>
      )}
    </div>
  )

  function removeMember() {
    form.state.selectedUserIds.replace(
      form.state.selectedUserIds.filter((id) => user.id !== id)
    )
    removeLead()
  }

  function removeLead() {
    form.state.selectedLeadUserIds.replace(
      form.state.selectedLeadUserIds.filter((id) => user.id !== id)
    )
  }

  function renderParentRoleChip() {
    if (!groupReporting) {
      return (
        <DiscoChip
          label={capitalize(membershipType)}
          color={membershipType === "admin" ? "blue" : "grey"}
          testid={`${TESTID}-type`}
        />
      )
    }
    if (membership.productMembership) {
      return (
        <ProductRoleTag
          testid={`${TESTID}.product-role`}
          productRole={membership.productMembership.role}
        />
      )
    }
    return (
      <OrganizationRoleTag
        testid={`${TESTID}.organization-role`}
        organizationRole={membership.organizationMembership.role}
      />
    )
  }
}

type StyleProps = {
  isGroupReportingActive: boolean
}

const useStyles = makeUseStyles((theme) => ({
  row: ({ isGroupReportingActive }: StyleProps) => ({
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateColumns: isGroupReportingActive ? "4fr 2fr 2fr 1fr" : "3fr 2fr 0fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: isGroupReportingActive ? "4fr 2fr 1fr" : "1fr 0fr",
    },
    borderRadius: theme.measure.borderRadius.large,
    padding: theme.spacing(0.5),
    alignItems: "center",
    width: "100%",
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[100],
    },
  }),
}))

export function AdminMemberGroupTableRowSkeleton() {
  return (
    <DiscoTable.Row>
      <TableCell width={"50%"}>
        <ProfileAvatarStackSkeleton stackSize={1} avatarSize={40} />
      </TableCell>
      <TableCell>
        <DiscoTagSkeleton />
      </TableCell>
      <TableCell>
        <DiscoIconButtonSkeleton />
      </TableCell>
    </DiscoTable.Row>
  )
}

export default observer(AdminMemberGroupTableRow)
